// export const alternative =
//   " 420,215 420,215 419,216 419,214 419,214 418,214 418,212 418,212 418,211 418,211 410,209 410,209 409,210 407,210 407,210 406,213 406,213 406,213 399,215 399,215 398,215 396,217 389,218 389,217 389,218 389,218 388,218 386,219 385,219 385,219 376,230 376,230 375,228 375,228 363,212 362,212 362,212 362,212 362,211 362,211 362,211 362,211 362,211 357,205 357,205 356,203 356,203 356,203 357,200 357,200 357,200 357,199 357,199 357,199 356,191 355,189 355,189 355,189 355,188 354,187 353,186 353,186 353,186 353,186 353,186 353,186 353,185 353,186 353,186 353,186 353,186 352,185 352,185 352,185 352,185 352,168 352,168 350,163 350,164 349,164 347,160 347,160 347,160 347,160 346,158 346,156 346,156 346,156 346,156 346,156 345,155 342,148 341,137 341,135 341,135 341,135 339,116 339,115 339,115 339,115 339,114 339,114 339,114 318,91 317,91 317,88 306,88 306,88 306,88 306,88 306,88 303,87 303,87 302,87 301,85 298,84 295,84 295,84 290,80 290,80 289,80 289,80 289,80 289,80 289,80 289,79 289,80 289,79 289,79 289,79 289,79 289,79 289,79 289,79 289,79 289,79 289,79 289,79 289,79 288,79 288,79 288,79 288,79 288,79 288,79 288,79 288,79 288,79 287,78 287,78 286,78 285,78 285,77 285,77 285,77 285,77 284,76 284,76 283,76 283,76 283,75 283,75 283,75 283,75 283,76 283,74 279,70 279,70 274,66 274,66 259,55 231,42 231,42 191,47 191,48 191,48 187,49 187,49 186,49 186,49 186,49 186,49 186,49 186,49 186,49 186,49 186,49 185,49 178,49 172,43 172,43 155,6 155,6 154,5 151,8 150,7 148,5 58,1 46,0 34,2 33,0 16,6 16,6 16,6 16,6 16,6 3,1 3,1 3,1 3,1 3,1 1,3 1,3 1,3 1,3 1,3 0,3 0,3 0,3 0,3 0,3 0,3 0,3 0,3 0,3";

export const alternative = [
  [420, 5],
  [420, 5],
  [420, 5],
  [420, 6],
  [420, 6],
  [420, 6],
  [420, 6],
  [420, 6],
  [420, 6],
  [420, 8],
  [420, 8],
  [420, 8],
  [420, 8],
  [419, 8],
  [419, 8],
  [419, 8],
  [419, 8],
  [418, 7],
  [418, 7],
  [418, 7],
  [418, 6],
  [418, 6],
  [418, 5],
  [417, 5],
  [417, 4],
  [417, 4],
  [417, 3],
  [417, 3],
  [417, 2],
  [417, 2],
  [416, 1],
  [416, 1],
  [416, 0],
  [416, 0],
  [416, 0],
  [416, 0],
  [416, 0],
  [415, 3],
  [414, 4],
  [404, 9],
  [398, 11],
  [377, 13],
  [336, 39],
  [324, 51],
  [324, 51],
  [324, 51],
  [304, 63],
  [296, 67],
  [179, 224],
  [179, 225],
  [179, 225],
  [178, 225],
  [178, 225],
  [178, 225],
  [178, 225],
  [178, 225],
  [178, 225],
  [178, 225],
  [178, 226],
  [178, 226],
  [178, 226],
  [178, 226],
  [177, 226],
  [177, 226],
  [175, 228],
  [170, 228],
  [170, 228],
  [169, 227],
  [169, 227],
  [163, 230],
  [157, 224],
  [157, 224],
  [155, 222],
  [155, 222],
  [155, 222],
  [154, 222],
  [154, 222],
  [153, 221],
  [153, 221],
  [153, 221],
  [153, 221],
  [152, 219],
  [152, 219],
  [148, 217],
  [148, 217],
  [145, 218],
  [145, 218],
  [137, 218],
  [129, 218],
  [76, 214],
  [21, 210],
  [6, 210],
  [3, 210],
  [3, 210],
  [3, 210],
  [1, 201],
  [0, 201],
  [0, 201],
  [0, 200],
  [0, 199],
  [0, 199],
  [0, 199],
  [0, 199],
  [0, 198],
  [0, 198],
  [0, 198],
  [0, 198],
];
